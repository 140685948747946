<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template v-else-if="category">
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>
            <template v-if="obj">Edit</template><template v-else>Add</template> Category
          </h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center">
          <span class="mr-3 hidden sm:block">Active</span>
          <AInputSwitch v-model="category.active" />
        </div>
      </div>

      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Description</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder=""
                v-model="category.description"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.category.description.required"
            >
              Description is required
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="SAVE"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
      category: {
        description: null,
        active: 1,
      },
    };
  },
  validations: {
    category: {
      description: {
        required,
      },
    },
  },
  mounted() {
    if (this.obj) {
      this.category = JSON.parse(JSON.stringify(this.obj));
    }
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/jobs/category/" +
              (!this.obj ? "create" : "update") +
              (!this.obj ? "" : "/" + this.obj.uid),
            JSON.stringify(this.category),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.user.uid;
    },
    user() {
      return this.$store.getters.user;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
  },
};
</script>